.signinbtn div {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    grid-gap: 10px;
  }
  hr {
    margin-top:40px;
    margin-bottom:20px;
  }
  .ml-5{
    margin-left:5px;
  }
  .change-login-link{
    font-size:14px;
  }
/* .signinbtn{
    min-width: 400px;
} */

.input2fa {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

.input2fa:focus {
  appearance: none;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}
