.BuilderPageContainer .BuilderPageContent {
  align-items: center;
  display: flex;
  height: calc(100% - 57px);
  justify-content: center;
  overflow: auto;
  padding: 10px;
}

.BuilderPageContainer .BuilderPageContent .content-placeholder {
  height: 100%;
  margin-top: 100px;
  position: relative;
  width: auto;
}

.Flow-Box {
  margin: 0 2.1rem 0 0;
  padding: 0.1rem 0 2.1rem;
  position: relative;
  width: 15rem;
}
.Flow-Box.Flow-Box__End-Label,
.Flow-Box.Flow-Box__Or-Label,
.Flow-Box.Flow-Box__Yes-Label {
  margin-right: 9.8rem;
}
.Flow-Box.Flow-Box__Bottom-Line:after {
  background: #c4cdd5;
  bottom: 0;
  content: "";
  display: block;
  left: calc(50% - 1px);
  position: absolute;
  top: 2px;
  width: 2px;
  z-index: 1;
}
.Flow-Box .Flow-Box__Content {
  background: #f2f4f7;
  border: 0.1rem solid #c4cdd5;
  border-radius: 0.7rem;
  padding: 0.7rem 0.7rem 0;
  position: relative;
  text-align: center;
  z-index: 2;
}
.Flow-Box .Flow-Box__Content.Flow-Box__Yes-Label:before {
  background: #bbe5b3;
  border: 0.1rem solid #96bb8f;
  border-radius: 1.4rem;
  color: #596f56;
  content: "Yes";
  display: block;
  /* font-size: 1.4rem; */
  font-weight: 700;
  height: 2.8rem;
  line-height: 2.8rem;
  position: absolute;
  right: -7.9rem;
  text-align: center;
  text-transform: uppercase;
  top: 3.1rem;
  width: 5.6rem;
  z-index: 3;
}
.Flow-Box .Flow-Box__Content.Flow-Box__Yes-Label:after {
  background: #c4cdd5;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  left: 100%;
  position: absolute;
  right: 0;
  right: -9.9rem;
  top: 4.4rem;
  z-index: 1;
}
.Flow-Box .Flow-Box__Content.Flow-Box__End-Label:before {
  background: #b3b8e5;
  border: 0.1rem solid #9094bb;
  border-radius: 1.4rem;
  color: #565970;
  content: "End";
  display: block;
  /* font-size: 1.4rem; */
  font-weight: 700;
  height: 2.8rem;
  line-height: 2.8rem;
  position: absolute;
  right: -7.9rem;
  text-align: center;
  text-transform: uppercase;
  top: 3.1rem;
  width: 5.6rem;
  z-index: 3;
}
.Flow-Box .Flow-Box__Content.Flow-Box__End-Label:after {
  background: #c4cdd5;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  left: 100%;
  position: absolute;
  right: 0;
  right: -4.3rem;
  top: 4.4rem;
  z-index: 1;
}
.Flow-Box .Flow-Box__Content.Flow-Box__Or-Label:before {
  color: #000;
  content: "OR";
  display: block;
  /* font-size: 1.4rem; */
  font-weight: 700;
  height: 2.8rem;
  line-height: 2.8rem;
  position: absolute;
  right: -7.9rem;
  text-align: center;
  text-transform: uppercase;
  top: 3.1rem;
  width: 5.6rem;
  z-index: 3;
}
.Flow-Box .Flow-Box__Content > h1 {
  background: #fff;
  border-radius: 0.7rem 0.7rem 0 0;
  color: #758ab9;
  font-size: 14px;
  font-weight: 700;
  margin: -0.7rem -0.7rem 0.7rem;
  padding: 1.05rem 0.7rem;
  text-transform: uppercase;
}
.Flow-Box .Flow-Box__No-Label {
  background: #e5b3b3;
  border: 0.1rem solid #bb8f8f;
  border-radius: 1.4rem;
  color: #6f5656;
  display: block;
  /* font-size: 1.4rem; */
  font-weight: 700;
  height: 2.8rem;
  left: calc(50% - 2.8rem);
  line-height: 2.8rem;
  margin-top: 2.1rem;
  position: absolute;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 5.6rem;
  z-index: 2;
}
.Flow-Case {
  display: flex;
  flex-direction: row;
}

.Flow-Box-Button {
  margin-bottom: 0.7rem;
  position: relative;
}
.Flow-Box-Button:hover > i {
  display: inline;
  display: initial;
}
.Flow-Box-Button.Flow-Box-Button__button-selected > button {
  border-color: rgba(92, 106, 196, 0.8) !important;
  box-shadow: 0 0 0 1px rgba(92, 106, 196, 0.8) !important;
}
.Flow-Box-Button.Flow-Box-Button__button-selected > i {
  display: inline;
  display: initial;
}
.Flow-Box-Button > button {
  border: 2px solid rgba(99, 115, 129, 0.2);
  padding: 1.0rem 1.6rem;
}
.Flow-Box-Button > button:focus {
  border-color: rgba(99, 115, 129, 0.2);
  box-shadow: none;
}
.Flow-Box-Button > button.shop-remove {
  background-color: #fff;
}
.Flow-Box-Button > button.is-empty {
  background-color: #f7f0df;
}
.Flow-Box-Button > button.is-error {
  background-color: #f7dfdf;
}
.Flow-Box-Button > i {
  background: #fff;
  border: 1px solid red;
  border-radius: 50px;
  color: red;
  cursor: pointer;
  display: none;
  font-size: 1.0rem;
  font-weight: 600;
  padding: 0px 4px;
  position: absolute;
  right: -7px;
  top: -7px;
  transition: all 0.2s;
  z-index: 9999;
}
.Flow-Box-Button > i:hover {
  background: #fbeae5;
  transform: scale(1.2);
}

.flow-box-button-item {
  background-color: #dee8f7;
  border-radius: 0.3rem;
  display: inline-block;
  padding: 0.5rem;
}
.flow-box-button-item.error {
  background-color: #efcfcf !important;
}
.conditions.all-conditions:not(:last-child):after {
  color: #7d88d0;
  content: "And";
  display: block;
  font-size: 1rem;
  font-weight: lighter;
  padding: 0.5rem;
  text-transform: uppercase;
}
.notifications-icons {
  bottom: -18px;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  right: -28px;
}
.notifications-icons .notification-icon {
  border-radius: 100%;
  height: 20px;
}

.conditions .condition:not(:last-child) {
  padding-bottom: 60px;
  position: relative;
}
.and-condition-divider:before {
  background: #b6bfc7;
  bottom: 0.6em;
  content: "";
  display: block;
  height: 0.1rem;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}
.and-condition-divider:after {
  background: #f4f6f8;
  bottom: 3px;
  color: #a8b2bc;
  content: "And";
  display: inline-block;
  font-size: 0.9em;
  font-weight: 700;
  left: 50%;
  line-height: 1;
  margin: 2rem 0 0;
  padding: 0 0.5em;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50%);
  z-index: 2;
}
.add-condition-form-layout {
  border-top: 1px solid #b6bfc7;
  margin-top: 50px;
  padding-top: 20px;
}
