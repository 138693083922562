.radio-image {
    position: relative;
    display: block;
    cursor: pointer;
    border: 2px solid transparent;
    padding: 5px;
    transition: border-color 0.2s ease-in-out;
  }
  
  .radio-image img {
    width: 100%;
    height: auto;
  }
  
  .radio-image:hover {
    border-color: #aaa;
  }
  
  .radio-image.selected {
    border-color: #007bff; /* Change this to your desired selected color */
  }
  
  .radio-image.selected::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background-color: #007bff; /* Change this to your desired tick color */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Add the Bootstrap icon as a checkmark */
  .radio-image.selected::before {
    content: "\f26e"; /* Bootstrap checkmark icon (bi-check) */
    font-family: "bootstrap-icons"; /* Set the Bootstrap Icons font-family */
    font-size: 18px;
    color: #fff;
  }

  :root{
  --bs-breadcrumb-divider: '>';
  }
  