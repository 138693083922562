.signinbtn div {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    grid-gap: 10px;
  }
  hr {
    margin-top:40px;
    margin-bottom:20px;
  }
  div.acceptterms{
    font-size:small;
    padding-top:10px;
    padding-bottom:10px;
  }
  .ml-5{
    margin-left:5px;
  }
/* .signinbtn {
    min-width: 400px;
} */