/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


.calendar-input-icons i { 
  position: relative;
  top:35px;
} 

.calendar-input-icons { 
  width: 100%; 
  margin-bottom: 10px; 
} 

.calendar-input-icons .icon { 
  padding: 15px;
  font-size:1rem;
} 

.calendar-input-icons .input-field { 
  padding-left: 40px;
  width:300px;
}

.cursor-pointer{
  cursor: pointer;
}
.pl-0{
  padding-left: 0px;
}
.pr-5{
  padding-right: 5px;
}
.pr-10{
  padding-right: 10px;
}
.pr-20{
  padding-right: 20px;
}
.pr-25{
  padding-right: 25px;
}
.pl-20{
  padding-left: 20px;
}

.pt-75rem{
  padding-top: 0.75rem;
}
.ml-5{
  margin-left: 5px !important;
}
.mr-5{
  margin-right: 5px;
}
.ml-10{
  margin-left: 10px !important;
}
.mr-10{
  margin-right: 10px;
}

.width-350{
  width: 350px;
}

.adjustmentPlatformIcon{
  width:25px;
}

.public-forms {
  margin: auto;
  max-width: 30rem;
  padding: 4rem 2rem;
}

.file-upload-border{
  border-width: 2px !important;
  border-style: dashed;
}

.tb-batches td, .tb-batches th {
  text-wrap: inherit;
}

.tb-campaigns td, .tb-campaigns th {
  text-wrap: inherit;
}

.tb-automations td, .tb-automations th {
  text-wrap: inherit;
}

.td-date{
  text-wrap: nowrap !important;
}
